/* eslint-disable @typescript-eslint/no-unused-vars */
// import axios from "axios";
import { useEffect, useState } from "react";

import { PageHeader } from "../../components/Base/PageHeader";
import { Link } from "react-router-dom";
import { Avatar } from "flowbite-react";
import axios, { AxiosResponse } from "axios";
import {
  LoyalCustomerI,
  selectLoyaltyPoints,
  SET_LOYALTY_POINT,
} from "../../features/loyalty/loyalty";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Spinner from "../../components/Base/Spinner";
import moment from "moment";
import EmptyState from "../../components/EmptyState";
import VarroeLogo from "../../assets/VarroeLogo.svg";

const LoyaltyProgram = () => {
  const dispatch = useAppDispatch();
  const loyaltyPoints = useAppSelector(selectLoyaltyPoints);

  const [loyalCustomers, setLoyalCustomers] = useState<LoyalCustomerI[]>([]);
  const [loading, setIsloading] = useState(false);

  const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const getProgramPoints = async () => {
    try {
      // !loyaltyPoints && setIsloading(true);
      const {
        data: { data },
      } = await axios.get(`/api/point/balance`);
      dispatch(SET_LOYALTY_POINT(data));
    } catch (e) {
      dispatch(SET_LOYALTY_POINT(0));
      console.error(e);
    }
  };

  const getProgramCustomer = async () => {
    try {
      !loyalCustomers.length && setIsloading(true);
      const {
        data: { data },
      } = await axios.get<
        unknown,
        AxiosResponse<{
          data: {
            result: LoyalCustomerI[];
          };
          message: string;
          status: number;
        }>
      >(`/api/point`);
      setLoyalCustomers(data.result);
    } catch (e) {
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };
  useEffect(() => {
    getProgramPoints();
    getProgramCustomer();
  }, []);
  return (
    <div className="h-screen w-full">
      <div className="h-full overflow-y-scroll flex flex-col gap-14">
        <PageHeader text="Varroe Points" />
        <div className="w-full flex justify-center">
          <div className="w-[670px] h-[109px] bg-[#D5D2F5] flex items-center justify-between rounded-2xl p-6 text-xl">
            <div className="flex bg-[#D9D9D9] w-[233px] h-[68px] rounded-md shadow-md font-extrabold text-center justify-center items-center">
              1 Booking = 50Vp
            </div>
            <img src={VarroeLogo} alt="background" width={80} />
            <div className="flex bg-[#D9D9D9] w-[233px] h-[68px] rounded-md shadow-md font-extrabold text-center justify-center items-center">
              1,000Vp = $100
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center items-center">
          <div className="h-[196px] w-[710px] shadow-xl bg-[#F1F1F1] rounded-xl pt-8 flex flex-col items-center justify-center text-center">
            <span className="text-5xl font-extrabold flex">
              {loading ? (
                <div className="flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                loyaltyPoints
              )}{" "}
              VP
            </span>
            <br />
            <Link to="/dashboard/loyalty-program/how-to-redeem">
              <button
                // disabled={loyaltyPoints ? loyaltyPoints < 1000 : true}
                className={`relative top-10 rounded-full shadow-xl w-[398px] h-[84px] uppercase font-extrabold text-4xl text-white ${
                  loyaltyPoints && loyaltyPoints < 1000
                    ? "bg-slate-600"
                    : "bg-[#7C8CE4]"
                }`}
              >
                <span className="drop-shadow-lg">REDEEM</span>
              </button>
            </Link>
          </div>
        </div>
        <table className="mt-10 table-auto border-separate border-spacing-y-3 mb-32">
          <thead className="text-gray-600">
            <tr>
              <th>
                <div className="flex justify-start">Name</div>
              </th>
              <th>Last Visited</th>
              <th>Average Spend</th>
              <th>Appointments</th>
              <th>Varroe Points</th>
            </tr>
          </thead>
          <tbody>
            {loyalCustomers.length
              ? loyalCustomers.map((loyalCustomer, index) => {
                  if (!loyalCustomer || !Object.keys(loyalCustomer).length) {
                    return null;
                  }
                  const {
                    customer,
                    lastVisit,
                    averageSpend,
                    appointmentCount,
                    pointsTotal,
                  } = loyalCustomer;
                  const fullName = customer.firstName
                    ? customer.fullName
                    : "Anonymous Customer";
                  return (
                    <tr key={index}>
                      <td>
                        <div className="flex gap-4 justify-start items-center">
                          <Avatar rounded placeholder={fullName} size={"sm"} />
                          <span>{fullName}</span>
                        </div>
                      </td>
                      <td>
                        <div className="flex justify-center">
                          {lastVisit ? moment(lastVisit).format("DDMMYY") : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="flex justify-center">
                          {USDollar.format(Number(averageSpend)) || 0}
                        </div>
                      </td>
                      <td>
                        <div className="flex justify-center">
                          {appointmentCount || "-"}
                        </div>
                      </td>
                      <td>
                        <div className="flex justify-center">
                          {pointsTotal || "-"}
                        </div>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
        {!loading && !loyalCustomers.length && (
          <div className="w-full">
            <EmptyState fetching={loading} text="No Loyal Customers :(" />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoyaltyProgram;
