import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { PageHeader } from "../../components/Base/PageHeader";
// import VarroeLogo from "../../assets/VarroeLogo.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectLoyaltyPoints,
  SET_LOYALTY_POINT,
} from "../../features/loyalty/loyalty";

const LoyaltyProgram = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate(); // Initialize navigate
  const loyaltyPoints = useAppSelector(selectLoyaltyPoints);
  const [showPopup, setShowPopup] = useState(false); // State to control the popup visibility

  const getProgramPoints = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`/api/point/balance`); // API endpoint to fetch points
      dispatch(SET_LOYALTY_POINT(data));
      console.log(loyaltyPoints);
    } catch (e) {
      dispatch(SET_LOYALTY_POINT(0));
      console.error("Error fetching points:", e);
    }
  };
  useEffect(() => {
    getProgramPoints();
  }, []);

  return (
    <div className="h-screen w-full">
      <div className="h-screen flex flex-col gap-12">
        {/* Flex container to align header text and arrow in one line */}
        <div className="flex items-center px-6 py-4">
          {/* Back Arrow */}
          <span
            className="text-2xl font-bold cursor-pointer mr-2"
            onClick={() => navigate(-1)} // Go back to the previous page
          >
            ⬅ {/* Thicker left arrow character */}
          </span>

          {/* Header Text */}
          <PageHeader text="Decide How You Want to Redeem" />
        </div>
        <div className="flex flex-col gap-40 justify-center items-center pt-8">
          <div className="w-full flex gap-4 justify-between px-20">
            <Link
              to={loyaltyPoints >= 1000 ? "voucher" : "#"}
              onClick={(e) => {
                if (loyaltyPoints < 1000) {
                  e.preventDefault(); // Prevent navigation if points are less than 1000
                  setShowPopup(true); // Show popup if points are less than 1000
                }
              }}
            >
              <div className="h-[261px] w-[390px] shadow-xl bg-[#E7DCFC] rounded-3xl flex items-center justify-center text-center cursor-pointer">
                <span className="text-xl font-extrabold">Redeem as Coupon</span>
              </div>
            </Link>
            <Link
              to={loyaltyPoints >= 1000 ? "gift" : "#"}
              onClick={(e) => {
                if (loyaltyPoints < 1000) {
                  e.preventDefault(); // Prevent navigation if points are less than 1000
                  setShowPopup(true); // Show popup if points are less than 1000
                }
              }}
            >
              <div className="h-[261px] w-[390px] shadow-xl bg-[#DCE8F6] rounded-3xl flex items-center justify-center text-center cursor-pointer">
                <span className="text-xl font-extrabold">
                  Redeem as Gift Card
                </span>
              </div>
            </Link>
          </div>

          {/* Popup Message */}
          {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div
                className="bg-white p-6 rounded-lg shadow-lg text-center"
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: "1000",
                }}
              >
                <p className="text-xl font-bold mb-4">
                  You need a thousand points to redeem
                </p>
                <button
                  onClick={() => setShowPopup(false)}
                  className="px-4 py-2  text-white rounded-lg bg-[#7C8CE4]"
                >
                  OK
                </button>
              </div>
            </div>
          )}

          {/* <div className="w-[670px] h-[109px] bg-[#D5D2F5] flex items-center justify-between rounded-2xl p-6 text-xl">
            <div className="flex bg-[#D9D9D9] w-[233px] h-[68px] rounded-md shadow-md font-extrabold text-center justify-center items-center">
              1 Booking = 50Vp
            </div>
            <img src={VarroeLogo} alt="background" width={80} />
            <div className="flex bg-[#D9D9D9] w-[233px] h-[68px] rounded-md shadow-md font-extrabold text-center justify-center items-center">
              1,000Vp = $100
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LoyaltyProgram;
