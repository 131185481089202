/* eslint-disable @typescript-eslint/no-unused-vars */
// import axios from "axios";
import { useEffect, useState } from "react";

import * as yup from "yup";
import { PageHeader } from "../../components/Base/PageHeader";
import { Field, Form, Formik } from "formik";
import { Input } from "../../components/Base/Input";
import { InputValidationError } from "../../components/InputValidationError";
import { Icon } from "@iconify/react";
import axios, { AxiosResponse } from "axios";
import { Toast } from "../../components/Base/toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../components/Base/Spinner";
import { Modal } from "flowbite-react";

const MyCoupon = ({
  entry,
  deleteRecord,
}: {
  entry: {
    firstName: string;
    lastName: string;
    email: string;
  };

  deleteRecord: (email: string) => void;
}) => {
  return (
    <form className="flex gap-8 w-max justify-evenly items-center text-center">
      <div>
        <Input
          disabled
          name="firstName"
          type="text"
          label="First Name"
          value={entry.firstName}
        />
      </div>
      <div>
        <Input
          name="lastName"
          type="text"
          label="Last Name"
          value={entry.lastName}
          disabled
        />
      </div>
      <div>
        <Input
          disabled
          name="email"
          type="email"
          label="Email"
          value={entry.email}
        />
      </div>
      <div className="flex gap-2 justify-center items-center pt-6">
        <button
          onClick={(e) => {
            e.preventDefault();
            deleteRecord(entry.email);
          }}
          className="bg-[#251D2A] text-white font-bold text-xl w-[32px] h-[32px] flex justify-center items-center"
        >
          <Icon icon={"ic:round-delete"} />
        </button>
      </div>
    </form>
  );
};
const schema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter your email address")
    .email("Please enter a valid email address"),
  firstName: yup.string().required("Please enter a first Name"),
  lastName: yup.string().required("Please enter a Last Name"),
});
const VoucherLoyaltyProgram = () => {
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setIsloading] = useState(false);
  const urlQuery = useLocation().search;
  const split = Number(new URLSearchParams(urlQuery).get("split")) || 1;
  const couponCode = new URLSearchParams(urlQuery).get("couponCode");
  const [customerToIssueCoupon, setCustomersToIssueCoupon] = useState<
    Array<{
      firstName: string;
      lastName: string;
      email: string;
    }>
  >([]);

  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

  const IssueCoupon = async () => {
    if (customerToIssueCoupon.length !== split) {
      Toast(
        `Number of customers must match the number of splits (${split})`,
        "error"
      );
      return;
    }
    try {
      setIsloading(true);
      await axios.post("/api/point/coupon/issue", {
        couponCode,
        customers: customerToIssueCoupon,
      });
      setOpenSuccessPopup(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message =
          (
            error.response as AxiosResponse<{
              status: string;
              data?: unknown;
              message?: string;
            }>
          )?.data?.message ?? error.message;
        Toast(message, "error");
      } else {
        Toast(
          "Something went wrong, please try again later or contact admin",
          "warning"
        );
      }
    } finally {
      setIsloading(false);
    }
  };
  useEffect(() => {
    if (!couponCode) {
      navigate("/dashboard/loyalty-program");
    }
  }, []);

  const removeIssuedCustomer = (email: string) => {
    const filteredCustomerlist = customerToIssueCoupon.filter(
      (c) => c.email !== email
    );
    setCustomersToIssueCoupon(filteredCustomerlist);
  };

  const getProgramPoints = async () => {};
  useEffect(() => {
    getProgramPoints();
  }, []);
  return (
    <div className="h-[90vh] w-full overflow-y-scroll">
      <div className="flex flex-col gap-10 h-full">
        <div className="flex items-center px-6 py-4">
          {/* Back Arrow */}
          <span
            className="text-2xl font-bold cursor-pointer mr-2"
            onClick={() => navigate(-1)} // Go back to the previous page
          >
            ⬅ {/* Thicker left arrow character */}
          </span>

          {/* Header Text */}
          <PageHeader text="Coupon Preview & Issue" />
        </div>
        <div className="w-full flex flex-col gap-20 justify-center items-center">
          <div className="rounded-lg bg-[#D9D9D9] w-full h-[120px]">
            <div className="bg-[#001C43] w-max p-2 px-4 text-white relative right-4 bottom-4 rounded-md font-bold text-center">
              Coupon Preview
            </div>
            <div className="flex justify-center items-center text-center text-4xl font-bold">
              <span>{couponCode}</span>
            </div>
          </div>
          <div className="rounded-lg bg-[#D9D9D9] w-full h-[420px]">
            <div className="bg-[#001C43] w-max p-2 px-4 text-white relative right-4 bottom-4 rounded-md font-bold text-center">
              Participating Customers
            </div>
            <div className="p-6">
              <div className="bg-[#D0C8D5] p-6 w-max">
                {customerToIssueCoupon.length
                  ? customerToIssueCoupon.map((entry, index) => (
                      <MyCoupon
                        entry={entry}
                        deleteRecord={removeIssuedCustomer}
                        key={index}
                      />
                    ))
                  : null}
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                  }}
                  onSubmit={(data, { resetForm }) => {
                    const existingEmail = customerToIssueCoupon.find(
                      (c) => c.email === data.email
                    );
                    if (existingEmail) {
                      alert("Email Already Added");
                      resetForm();
                      return;
                    }
                    setCustomersToIssueCoupon((prev) => [...prev, data]);
                    resetForm();
                  }}
                  validationSchema={schema}
                >
                  {({ errors, touched }) => (
                    <Form className="flex gap-8 w-full justify-evenly items-center text-center">
                      <div>
                        <Field
                          name="firstName"
                          type="text"
                          as={Input}
                          label="First Name"
                        />

                        {errors.firstName && touched.firstName ? (
                          <InputValidationError text={errors.firstName} />
                        ) : null}
                      </div>
                      <div>
                        <Field
                          name="lastName"
                          type="text"
                          as={Input}
                          label="Last Name"
                        />

                        {errors.lastName && touched.lastName ? (
                          <InputValidationError text={errors.lastName} />
                        ) : null}
                      </div>
                      <div>
                        <Field
                          name="email"
                          type="email"
                          as={Input}
                          label="Email"
                        />

                        {errors.email && touched.email ? (
                          <InputValidationError text={errors.email} />
                        ) : null}
                      </div>
                      <div className="flex gap-2 justify-center items-center pt-6">
                        <button
                          type="submit"
                          className="bg-[#251D2A] text-white font-bold text-xl w-[32px] h-[32px]"
                        >
                          +
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end text-xl">
            <button
              onClick={IssueCoupon}
              className="relative right-4 rounded p-2 bg-[#001C43] text-center text-sm font-bold text-white h-max flex justify-center"
            >
              {loading ? (
                <div className="flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                <span>Issue Coupon</span>
              )}
            </button>
            <Modal
              show={openSuccessPopup}
              size="md"
              onClose={() => {
                navigate("/dashboard/loyalty-program");
                setOpenSuccessPopup(false);
              }}
              popup
            >
              <Modal.Header />
              <Modal.Body>
                <div className="text-center flex flex-col justify-center items-center">
                  <Icon
                    icon={"icon-park:success"}
                    className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
                  />
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    Customer Coupon Issued Successfully
                  </h3>

                  <Link
                    to={"/dashboard/loyalty-program"}
                    className="relative right-4 rounded p-2 bg-[#001C43] text-center text-sm font-bold text-white h-max flex justify-center"
                  >
                    Redeem More Points
                  </Link>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherLoyaltyProgram;
