import React from "react";
import AppointmentBanner from "../components/AppointmentBanner";
import { useLocation } from "react-router-dom";
import { Navigation } from "../components/Navigation";
import StatisticsBanner from "../components/StatisticsBanner";
import { useAppSelector } from "../store/hooks";
import StaffAppointmentBanner from "../components/StaffAppointmentBanner";
import LoyaltyProgramBanner from "../components/LoyaltyProgramBanner";
interface Props {
  children?: React.ReactNode;
}
const Dashboard: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const userRole = useAppSelector((state) => state.auth.role);
  return (
    <div className="flex w-full h-screen overflow-x-hidden overflow-y-auto">
      <Navigation />
      <div className="w-full h-screen p-4 overflow-y-hidden bg-white md:p-8 bg-pattern">
        <div
          className={`${
            userRole === "staff" &&
            location.pathname !== "/dashboard/appointments"
              ? " flex justify-between "
              : ![
                  "/dashboard/analytics",
                  "/dashboard/staff",
                  "/dashboard/crm",
                  "/dashboard/loyalty-program",
                  "/dashboard/loyalty-program/how-to-redeem",
                ].includes(location.pathname) &&
                " grid grid-cols-3 md:grid-cols-4 lg:space-x-10 "
          }`}
        >
          {userRole !== "staff" ? (
            <div className="col-span-4 space-y-5 sm:col-span-2 md:col-span-3 md:space-y-10">
              {props.children}
            </div>
          ) : location.pathname === "/dashboard/appointments" ? (
            <div className="col-span-4 space-y-5 sm:col-span-2 md:col-span-3 md:space-y-10">
              {props.children}
            </div>
          ) : (
            <StaffAppointmentBanner />
          )}

          {![
            "/dashboard/analytics",
            "/dashboard/staff",
            "/dashboard/crm",
            "/dashboard/loyalty-program",
            "/dashboard/loyalty-program/how-to-redeem",
          ].includes(location.pathname) && (
            <div className="hidden sm:block">
              {location.pathname.includes("loyalty-program") ||
              location.pathname.includes("loyalty-program") ? (
                <LoyaltyProgramBanner />
              ) : userRole === "merchant" ? (
                <AppointmentBanner />
              ) : userRole === "freelancer" ? (
                <AppointmentBanner />
              ) : userRole === "staff" ? (
                <StatisticsBanner />
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
