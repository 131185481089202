/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";

import { PageHeader } from "../../components/Base/PageHeader";
// import { useAppDispatch, useAppSelector } from "../../store/hooks";
// import {
//   selectLoyaltyPoints,
//   SET_LOYALTY_POINT,
// } from "../../features/loyalty/loyalty";
import axios, { AxiosResponse } from "axios";
import { Spinner } from "flowbite-react";
import { Toast } from "../../components/Base/toast";
import { useNavigate } from "react-router-dom";

// 1000vp = $100 1
const VoucherLoyaltyProgram = () => {
  const [split, setSplit] = useState<number>(1);
  const [loading, setIsloading] = useState(false);
  const navigate = useNavigate();
  // const vp = useAppSelector(selectLoyaltyPoints);
  const totalValue = 100; // Total value of the coupon in dollars
  const valuePerCoupon = totalValue / split; // Calculate the value of each coupon

  const crementSplit = (increase: boolean) => {
    setSplit(split + (increase ? 1 : -1));
  };

  const createCoupon = async () => {
    try {
      setIsloading(true);
      const { data } = await axios.post<
        unknown,
        AxiosResponse<{
          status: number;
          message: string;
          data: string;
        }>
      >("/api/point/redeem/coupon", {
        split,
      });
      const couponCode = data?.data || "";
      Toast(`Coupon created successfully`, "success");
      navigate(`preview?couponCode=${couponCode}&split=${split}`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message =
          (
            error.response as AxiosResponse<{
              status: string;
              data?: unknown;
              message?: string;
            }>
          )?.data?.message ?? error.message;
        Toast(message, "error");
      } else {
        Toast(
          "Something went wrong, please try again later or contact admin",
          "warning"
        );
      }
    }
    setIsloading(false);
  };
  return (
    <div className="h-screen w-full">
      <div className="h-full overflow-x-hidden flex flex-col gap-12 ">
        {/* Flex container to align header text and arrow in one line */}
        <div className="flex items-center px-6 py-4">
          {/* Back Arrow */}
          <span
            className="text-2xl font-bold cursor-pointer mr-2"
            onClick={() => navigate(-1)} // Go back to the previous page
          >
            ⬅ {/* Thicker left arrow character */}
          </span>

          {/* Header Text */}
          <PageHeader text="Redeem as Coupon" />
        </div>
        <div className="p-4 w-full flex flex-col gap-20 justify-center items-center">
          <div className="bg-[#EEEEEE] w-[622px] text-white font-bold flex flex-col items-center rounded-2xl shadow-xl">
            <button className="relative bottom-4 rounded w-[381px] h-[36px] bg-[#001C43] text-center text-xl shadow-md">
              <span>Coupon Split</span>
            </button>
            <div className="w-full flex justify-between gap-6 pl-8">
              <div className="flex w-full p-4 gap-12 text-2xl text-center items-center justify-between text-black font-bold">
                <span>$ 100</span>
                <div className="border-l-2 border-black h-full" />
                <span>{split} Slips</span>
                <div className="flex flex-col gap-[2px]">
                  <button
                    className="bg-[#E2E0E0] w-[28px] h-[46px] flex justify-center items-center rounded-full"
                    onClick={() => crementSplit(true)}
                  >
                    <div className="rounded-full bg-purple-900 w-2 h-2" />
                  </button>
                  <button
                    className="bg-[#E2E0E0] w-[28px] h-[46px] flex justify-center items-center rounded-full"
                    onClick={() => crementSplit(false)}
                    disabled={split <= 1} // Disable if split is 1 or less
                  >
                    <div className="rounded-full bg-purple-900 w-2 h-2" />
                  </button>
                </div>
              </div>
            </div>
            {/* Add the Split Information Inside the Coupon Split Div */}
            <div className="text-center mt-4 text-black">
              {split > 1 ? (
                <p className="text-lg font-bold">
                  You will receive {split} coupons, each worth $
                  {valuePerCoupon.toFixed(2)}
                </p>
              ) : (
                <p className="text-lg font-bold">
                  You will receive 1 coupon worth $100
                </p>
              )}
            </div>
          </div>

          {/* <div className="shadow-2xl rounded-3xl w-[523px] h-[101px] flex items-center">
            <div className="relative right-4 rounded p-2 bg-[#001C43] text-center text-sm text-white h-max">
              <span>Enter Coupun Code</span>
            </div>
            <PinInput
              length={6}
              initialValue=""
              onChange={(value, index) => {}}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "black" }}
              inputFocusStyle={{ borderColor: "blue" }}
            />
          </div> */}
          <div className="w-full flex justify-end text-xl">
            <button
              onClick={createCoupon}
              className="relative right-4 rounded p-2 bg-[#001C43] text-center text-sm font-bold text-white h-max flex justify-center"
            >
              {loading ? (
                <div className="flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                <span>Create Coupon</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherLoyaltyProgram;
