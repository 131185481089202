import { useEffect, useState } from "react";
import { PageHeader } from "../../components/Base/PageHeader";
import { useNavigate } from "react-router-dom";
import giftVendor1 from "../../assets/images/amazonLogo.jpeg";
import giftVendor2 from "../../assets/images/204FuelLogo.png";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import axios from "axios";
import Spinner from "../../components/Base/Spinner";
import {
  selectLoyaltyPoints,
  SET_LOYALTY_POINT,
} from "../../features/loyalty/loyalty";

const GiftLoyaltyProgram = () => {
  const giftVendorImages = [giftVendor1, giftVendor2];
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loyaltyPoints = useAppSelector(selectLoyaltyPoints);
  const [loading, setIsloading] = useState(false);

  const getProgramPoints = async () => {
    try {
      setIsloading(true);
      const {
        data: { data },
      } = await axios.get(`/api/point/balance`);
      dispatch(SET_LOYALTY_POINT(data));
    } catch (e) {
      dispatch(SET_LOYALTY_POINT(0));
      console.error(e);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getProgramPoints();
  }, []);

  return (
    <div className="h-screen w-full flex flex-col">
      {/* Flex container to align header text and arrow in one line */}
      <div className="flex items-center px-6 py-2 bg-white shadow-md">
        {/* Back Arrow */}
        <span
          className="text-2xl font-bold cursor-pointer mr-2"
          onClick={() => navigate(-1)} // Go back to the previous page
        >
          ⬅ {/* Thicker left arrow character */}
        </span>

        {/* Header Text */}
        <PageHeader text="Redeem as Gift Card" />
      </div>

      <div className="flex-grow w-full flex flex-col items-center gap-4 p-4 overflow-auto">
        <div className="bg-[#EEEEEE] w-[622px] p-4 text-black font-bold flex flex-col items-center rounded-2xl shadow-xl text-2xl gap-2 mb-2">
          <p>Gift Card Total</p>
          <div className="w-max p-4 px-8 bg-[#D9D9D9] rounded-xl drop-shadow-lg">
            {loading ? (
              <div className="flex justify-center items-center">
                <Spinner />
              </div>
            ) : (
              `$${(loyaltyPoints / 10).toLocaleString()}`
            )}{" "}
          </div>
        </div>

        <div className="rounded-3xl w-[425px] p-2 bg-[#001C43] text-center text-xl text-white font-bold mb-2">
          <span>Select Gift Card Vendor</span>
        </div>

        {/* Grid of Gift Vendor Images */}
        <div className="grid md:grid-cols-3 gap-6 mb-4">
          {[...Array(9)].map((_, index) => (
            <div
              key={index}
              className="w-[140px] h-[85px] rounded-xl drop-shadow-lg bg-[#D9D9D9] overflow-hidden flex justify-center items-center"
            >
              {index < 2 ? (
                <img
                  src={giftVendorImages[index]}
                  alt={`Vendor ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="flex justify-center items-center text-center text-gray-600 font-bold">
                  Coming Soon
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Button Container */}
      <div className="w-full bg-white p-10 flex justify-end">
        <button className="rounded p-2 bg-[#001C43] text-center text-sm font-bold text-white">
          <span>Redeem</span>
        </button>
      </div>
    </div>
  );
};

export default GiftLoyaltyProgram;
